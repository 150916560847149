import * as React from "react";
import { WorkContents, Container, Breadcrumb } from "../components";
import Seo from "../components/Seo";

const WorkPage = () => {
  return (
    <Container>
      <Seo
        title="実績紹介"
        description="TKCホールディングの制作実績一覧です。経営者や事業責任者の描いているイメージをそのままコーディングにより具現化します。"
      />
      <WorkContents />
      <Breadcrumb currentPage="Work" currentSlug="/work" />
    </Container>
  );
};

export default WorkPage;
